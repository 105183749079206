import React from 'react';
import { NavLink } from 'react-router-dom';

import MobileToggle from '../MobileToggle/MobileToggle';
import Leftnavigation from '../LeftNavigation.js/Leftnavigation';
import NavigationItems from '../NavigationItems/NavigationItems';

import './MainNavigation.css';

const mainNavigation = props => (
  <div style={{ width: "100%", height: "100%" }}>
    <nav className="main-nav">
      <MobileToggle onOpen={props.onOpenMobileNav} />
      <div className="main-nav__logo">
        <NavLink to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin" rel="canonical">
        </NavLink>
      </div>
      <div className="spacer" />
      <ul className="main-nav__items">
        <NavigationItems isAuth={props.isAuth} onLogout={props.onLogout} />
      </ul>
    </nav>
    {
      (props.isAuth) ? (
        <>
          <Leftnavigation userId={props.userId} token={props.token} />
        </>
      ) : (
        null
      )
    }
  </div>
);

export default mainNavigation;
