import React, { useState } from "react";
import '../Components/css/Navbar.css'
import ServiceDropdown from './ServiceDropdown'
import { HashLink as Link } from "react-router-hash-link";

/*
*********************************************************************************************************
*  @Parent Component           :    Main Page
*  @React Component Name       :    Navbar2
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Current NavBar
********************************************************************************************************
*/

export default function Navbar2() {

	const [fix, setFix] = useState(false);
	const [isActive, setIsActive] = useState(false);

	/*
	*********************************************************************************************************
	*   @React Component Name       :       Navbar2
	*   @Method                     :       setFixed
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       this method return true scroll position is 100 then return true otherwise false 
	*********************************************************************************************************
	*/

	function setFixed() {
		if (window.pageYOffset > 100) {
			setFix(true);
		} else {
			setFix(false);
		}
	}

	/*
	*********************************************************************************************************
	*   @React Component Name       :       Navbar2
	*   @Method                     :       handleClick
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       this method remove the dropDown onClick on the mobile view
	*********************************************************************************************************
	*/

	const handleClick = event => {
		setIsActive(current => !current);
	};

	/*
	*********************************************************************************************************
	*   @React Component Name       :       Navbar2
	*   @Method                     :       handleState
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       this method set the state of the active or not   
	*********************************************************************************************************
	*/

	function handleState() {
		setIsActive(current => !current);
	}

	window.addEventListener("scroll", setFixed);
	return (
		<div className="header2 header">
			<nav className="nav container ">
				<div className="nav__data">
					<>
						<div className="flexClass">
							<a className="nav__logo" href="/">
								<img
									src="/assets/ant_White_logo_26.png"
									className="logo"
									alt="logo"

									style={{ color: "#ffffff" }} />
							</a>
							<a className="nav__logo" href="/">
								<img
									src="/assets/badge.png"
									className="logo2"
									alt="logo"

									style={{ color: "#ffffff" }} />
							</a>
						</div>
					</>
					<div className="nav__toggle" id="nav-toggle">
						<i className={isActive ? ` ri-close-line nav__toggle-close  "secont_color" ` : `ri-menu-line nav__toggle-menu  "secont_color"}`} onClick={handleClick} ></i>
					</div>
				</div>
				<div className={isActive ? "nav__menu show-menu " : "nav__menu "} id="nav-menu">
					<div className="mobile-view-menu container">
						<div className="flexClass">
							<a className="nav__logo" href="/">
								<img
									src="/assets/Antrazal_trns.png"
									className="logo_b"
									alt="logo"
								/>
							</a>
							<a className="nav__logo" href="/">
								<img
									src="/assets/badge.png"
									className="logo_b2"
									alt="logo"

									style={{ color: "#ffffff" }} />
							</a>
						</div>
						<div className="mobile-view-toggle-cross" id="nav-toggle">
							<i className=' ri-close-line  secont_color ' onClick={handleClick} ></i>
						</div>
					</div>
					<ul className="nav__list container">
						<Link  rel="canonical" to="/#home">
							<li className="nav__link" onClick={() => setIsActive(!isActive)} >Home </li>
						</Link>
						<ServiceDropdown fix={fix} handleState={handleState} />
						<Link  rel="canonical" to="/#work">
							<li className="nav__link" onClick={() => setIsActive(!isActive)}>Work</li>
						</Link>
						<Link  rel="canonical" to="/aboutUs">
							<li className="nav__link" onClick={() => setIsActive(!isActive)}>About</li>
						</Link>
						<Link  rel="canonical" to="/blog">
							<li className="nav__link" onClick={() => setIsActive(!isActive)}>Blog </li>
						</Link>
					</ul>
				</div>
			</nav>
		</div>

	)
}
