// export const TabTitle=(newTitle,newDescription)=>{
//         document.title = newTitle;
    
//         const metaDescription = document.querySelector("meta[name='description']");
//         if (metaDescription) {
//             metaDescription.setAttribute("content", newDescription);
//         } else {
//             const newMeta = document.createElement("meta");
//             newMeta.name = "description";
//             newMeta.content = newDescription;
//             document.head.appendChild(newMeta);
//         }
    
// }

export const TabTitle = (newTitle, newDescription) => {
    // Update the document title
    if (newTitle) {
        document.title = newTitle;
    }

    // Update or create the meta description
    if (newDescription) {
        let metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute("content", newDescription);
        } else {
            metaDescription = document.createElement("meta");
            metaDescription.name = "description";
            metaDescription.content = newDescription;
            document.head.appendChild(metaDescription);
        }
    }
};
