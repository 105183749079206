import React, { useEffect, useState } from 'react'
import './../Components/css/caseStudy.css'
import Slider from "react-slick";
import OtherServices from '../Components/OtherServices';
import SetMeeting from '../Components/SetMeeting'
import CarouselLogo from '../Components/CarouselLogo';
import { Link } from 'react-router-dom';
import { Button, Row, Col, Container } from 'react-bootstrap';

/*
*********************************************************************************************************
*  @Parent Component           :    App Home Page
*  @React Component Name       :    CaseStudy
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       CaseStudy page 
********************************************************************************************************
*/

export default function CaseStudy() {

	const [title, setTitle] = useState("")
	const [description, setDescription] = useState("")
	const [iconDiv, setIconDiv] = useState([])
	const [posts, setPosts] = useState([]);
	const [cardIndex, setCardIndex] = useState(0)
	
	/*
*********************************************************************************************************
*   @React Component Name       :       CaseStudy
*   @Method                     :       useEffect
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       initialy call the function or rendering   
*********************************************************************************************************
*/

	useEffect(() => {
		fetchPosts();
	}, []);
		
	/*
*********************************************************************************************************
*   @React Component Name       :       CaseStudy
*   @Method                     :       fetchPosts
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       fetch the data from the server  
*********************************************************************************************************
*/

	const fetchPosts = () => {
		fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/caseStudy`)
			.then((res) => res.json())
			.then((resData) => {
				if (resData.length > 0) {
					const firstCard = resData[0];
					setTitle(firstCard.name);
					setDescription(firstCard.shotDescriptions);
					setIconDiv(firstCard.multiImages);
				}
				setPosts(resData);
			})
			.catch((err) => {
				console.error('Error fetching blog posts:', err);

			});

	};

		/*
*********************************************************************************************************
*   @React Component Name       :       CaseStudy
*   @Method                     :       handleSlideChange
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       handel the click on the dard then thow th detail on the left side of the page   
*********************************************************************************************************
*/

	const handleSlideChange = (index) => {
		const data = posts[index]
		setCardIndex(data.id)
		setTitle(data.name)
		setDescription(data.shotDescriptions)
		setIconDiv(data.multiImages)
	}

	const settings = {
		dots: true,
		arrows: false,
		centerPadding: '60px',
		slidesToShow: 3,
		focusOnSelect: true,
		afterChange: (index) => handleSlideChange(index),
		responsive: [
			{
				breakpoint: 760,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					Infinity: true,
					slidesToShow: 1,
					focusOnSelect: true,
				},
			}],
	}

	return (
		<>
			<div className='Case-main-div'>
				<Container >
					<div className='container-class'>
						<div className='detail_card'>
							<div className='inner-detail_card' >
								<h3 style={{ color: "white" }}>{title}</h3>
								<p style={{ color: "white" }}>{description}</p>
								<div>
									<Row className="" style={{ width: "100%", height: "4rem" }}>
										{
											iconDiv.map((relitem) => {
												return (
													<Col xs lg="1" style={{ width: "100%", height: "100%", margin: "1rem" }}><img src={relitem.url} style={{ height: "100%" }} alt="img" /></Col>
												)
											})
										}
									</Row>
								</div>
							</div>
						</div>
						<div className='slider-div'>
							<Slider {...settings} height={{ height: "80vh" }}>
								{
									posts.map((metadata) => (
										<div className="card" style={{ backgroundColor: "white" }} key={metadata.id}
											onClick={() => {
												setCardIndex(metadata.id)
												setTitle(metadata.name)
												setDescription(metadata.shotDescriptions)
												setIconDiv(metadata.multiImages)
											}
											}
										>
											<div className="card-top" style={{ backgroundColor: "white" }} >
												<img
													src={metadata.image} alt="img"
												/>
											</div>
											<div className="card-bottom">
												<h6 className='para-limit1'>{metadata.name}</h6>
												<p className='para-limit'>{metadata.shotDescriptions}</p>

											</div>
											<div className='case-card-button-div'>
												<Button className=" view-btn" >
													<Link  rel="canonical"
														to={{
															pathname: "/blog/page",
															search: `?viewmore=${metadata.name}`,
														}}>
														View More
													</Link>
												</Button>
											</div>
										</div>
									))
								}
							</Slider>
						</div>
					</div>
				</Container>
			</div>
			<CarouselLogo />
			<SetMeeting />
			<OtherServices />
		</>
	)
}
