import React from "react";
import { Container } from "react-bootstrap";
import '../Components/css/roiService.css'

function RoiFocused() {
	return (
		<>
			<section className="roiouterSection">
				<Container>
					<h2 className="heading" style={{ textAlign: "start",fontSize:"2.3rem"}}>
						Our ROI focused approach
						<br/>
						 to successful project deliveries
					</h2>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<div className="roiServiceSetction">
							<div className="roiServiceSetction-inner-div">
								<h3>100+ Salesforce CRM Implementation</h3>
								<h2>Sales/Service cloud</h2>
								<h2>Customer Development</h2>
								<h2>Integrations</h2>
							</div>
							<div className="roiServiceSetction-inner-div">
								<h3>100+ Salesforce CRM Implementation</h3>
								<h2>Sales/Service cloud</h2>
								<h2>Customer Development</h2>
								<h2>Integrations</h2>
							</div>
							<div className="roiServiceSetction-inner-div">
								<h3>100+ Salesforce CRM Implementation</h3>
								<h2>Sales/Service cloud</h2>
								<h2>Customer Development</h2>
								<h2>Integrations</h2>
							</div>
						</div>
					</div>
				</Container>
			</section>
		</>
	);
}

export default RoiFocused;
