// import React, { useState, useEffect } from 'react';
// import { useSearchParams } from 'react-router-dom';
// import CarouselLogo from '../Components/CarouselLogo';
// import ScrollToTop from './ScrollToTop';
// import AreasExpertise from '../Components/AreasExpertise';
// import RoiFocused from '../Components/RoiFocused';
// import CountUpSection from '../Components/CountUpSection';
// import SetMeeting from '../Components/SetMeeting';
// import { HashLink as Link } from "react-router-hash-link";
// import ServicesDetailsMetaData from '../Data/ServicesDetailsMetaData';
// import '../Components/css/Srvsales.css';
// import { TabTitle } from './TabTitle';
// /*
// *********************************************************************************************************
// *  @Parent Component           :    
// *  @React Component Name       :    Sales
// *  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
// *  @Company                    :    Antrazal
// *  @Date                       :    05-07-2024
// * 
// *******************************************************************************************************
// * Developer                                             TITLE
// * <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       service section to Section
// ********************************************************************************************************
// */
// export default function Sales() {
// 	const [posts, setPosts] = useState([]);
// 	const [searchParams] = useSearchParams();
// 	let name = searchParams.get('page');
// 	name = name.replace(/%26/g,'&')
// 	const [SerLogo, setServiceLogo] = useState('')
// 	const [arrayOfItems, setArrayOfItems] = useState({
// 		id: '',
// 		items: [],
// 	});
// 	TabTitle(`${name || 'Services'}  | Salesforce Development Services | Antrazal','Antrazal’s salesforce consulting company delivers exceptional Salesforce services worldwide. With global expertise and a team of experts.`)
// 	/*
// *********************************************************************************************************
// *   @React Component Name       :       Sales
// *   @Method                     :       fetchPosts
// *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
// *   @Company                    :       Antrazal
// *   @Description                :       fetch the data from the server for the service page 
// *********************************************************************************************************
// */
// 	const fetchPosts = () => {
// 		fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/services`)
// 			.then((res) => res.json())
// 			.then((resData) => {
// 				if (resData.length > 0) {
// 					const firstCard = resData.find((item) => item.name === name);

// 					if (firstCard) {
// 						const match = firstCard.content.match(/<li>.*?<\/li>/g);
// 						const items = match ? match.map((m) => m.slice(4, -5)) : [];
// 						setArrayOfItems({
// 							id: firstCard.name,
// 							items: items,
// 						});
// 					}
// 				}
// 				setPosts(resData);
// 			})
// 			.catch((err) => {
// 				console.error('Error fetching blog posts:', err);
// 			});
// 	};

// 	/*
// *********************************************************************************************************
// *   @React Component Name       :       Sales
// *   @Method                     :       setServiceImage
// *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
// *   @Company                    :       Antrazal
// *   @Description                :       set the service image as the path of the url 
// *********************************************************************************************************
// */

// 	const setServiceImage = () => {
// 		ServicesDetailsMetaData.map((ServicesDetails) => {
// 			ServicesDetails.path === name ? setServiceLogo(ServicesDetails.img) : setServiceLogo(null)
// 		})
// 	}

// 	/*
// *********************************************************************************************************
// *   @React Component Name       :       Sales
// *   @Method                     :       useEffect
// *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
// *   @Company                    :       Antrazal
// *   @Description                :       initialy call 
// *********************************************************************************************************
// */

// 	useEffect(() => {
// 		fetchPosts();
// 		setServiceImage()
// 	}, [name]);
//     const extractImageName = (url) => {
//         if (!url) return "Unknown Image";
//         const parts = url.split("/");
//         return parts[parts.length - 1].split(".")[0]; // Extract name without extension
//     };
// 	return (
// 		<div>
// 			{posts.map((item) =>
// 				item.name === name ? (
// 					<div key={item.id}>
// 						<div className="headingbg">
// 							<div className="sevcontainer container">
// 								<div className="content-heading-div">
// 									<div className="heading_details">
// 										<div className='service-img-container'>
// 											<img src='/assets/salesforce-with-type-logo.svg' alt="" className='salesforcelogo' />
// 											<div className='service-icon'>
// 												<img key={item.serviceIcon} src={item.serviceIcon} alt={extractImageName(item.serviceIcon || '')} />
// 											</div>
// 										</div>
// 										<div>
// 											<h1 style={{marginTop:'1rem'}}>
// 												<span style={{color:"#0d9dda"}}>{item.name}</span>
// 											</h1>
// 										</div>
// 										<div >
// 											<li dangerouslySetInnerHTML={{ __html: item.content }}></li>
// 										</div>
// 										<Link  rel="canonical" to="/#contact">
// 											<button className="service-contact-btn" variant="primary">Meet an Expert
// 											</button>
// 										</Link>
// 									</div>

// 								</div>
// 							</div>
// 						</div>
// 						<CarouselLogo />
// 						<ScrollToTop />
// 						<AreasExpertise AreasExpertise={item.areaOfExpertises} id={item.id} />
// 						<RoiFocused />
// 						<CountUpSection />
// 						<SetMeeting />
// 					</div>
// 				) : null
// 			)}
// 		</div>
// 	);
// }



import React, { useState, useEffect } from 'react';
import { useSearchParams,useParams,useLocation } from 'react-router-dom';
import CarouselLogo from '../Components/CarouselLogo';
import ScrollToTop from './ScrollToTop';
import AreasExpertise from '../Components/AreasExpertise';
import RoiFocused from '../Components/RoiFocused';
import CountUpSection from '../Components/CountUpSection';
import SetMeeting from '../Components/SetMeeting';
import { HashLink as Link } from "react-router-hash-link";
import ServicesDetailsMetaData from '../Data/ServicesDetailsMetaData';
import '../Components/css/Srvsales.css';
import { TabTitle } from './TabTitle';

/*
*********************************************************************************************************
*  @Parent Component           :    
*  @React Component Name       :    Sales
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       service section to Section
********************************************************************************************************
*/
// export default function Sales() {
// 	const [posts, setPosts] = useState([]);
// 	const pathname = window.location.pathname; 
// const name = pathname.replace(/^\/services\//, ''); // Remove "/services" from the beginning
// console.log(name);
// 	console.log('searchParams',name)
// 	const [SerLogo, setServiceLogo] = useState('')
// 	const [arrayOfItems, setArrayOfItems] = useState({
// 		id: '',
// 		items: [],
// 	});
// 	TabTitle(`${name || 'Services'}  | Salesforce Development Services | Antrazal','Antrazal’s salesforce consulting company delivers exceptional Salesforce services worldwide. With global expertise and a team of experts.`)
// 	/*
// *********************************************************************************************************
// *   @React Component Name       :       Sales
// *   @Method                     :       fetchPosts
// *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
// *   @Company                    :       Antrazal
// *   @Description                :       fetch the data from the server for the service page 
// *********************************************************************************************************
// */
// 	const fetchPosts = () => {
// 		fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/services`)
// 			.then((res) => res.json())
// 			.then((resData) => {
// 				if (resData.length > 0) {
					
// 					// const firstCard = resData.find((item) => item.name === name);
// 					const firstCard = resData.find((item) => {
//                         const formattedName = item.name.toLowerCase().replace(/\s+/g, '-');
//                         return formattedName == name;
//                     });
// 					console.log('firstCard', firstCard)

// 					if (firstCard) {
// 						const match = firstCard.content.match(/<li>.*?<\/li>/g);
// 						const items = match ? match.map((m) => m.slice(4, -5)) : [];
// 						setArrayOfItems({
// 							id: firstCard.name,
// 							items: items,
// 						});
// 					}
// 				}
// 				setPosts(resData);
// 			})
// 			.catch((err) => {
// 				console.error('Error fetching blog posts:', err);
// 			});
// 	};

// 	/*
// *********************************************************************************************************
// *   @React Component Name       :       Sales
// *   @Method                     :       setServiceImage
// *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
// *   @Company                    :       Antrazal
// *   @Description                :       set the service image as the path of the url 
// *********************************************************************************************************
// */

// 	const setServiceImage = () => {
// 		ServicesDetailsMetaData.map((ServicesDetails) => {
// 			ServicesDetails.path === name ? setServiceLogo(ServicesDetails.img) : setServiceLogo(null)
// 		})
// 	}

// 	/*
// *********************************************************************************************************
// *   @React Component Name       :       Sales
// *   @Method                     :       useEffect
// *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
// *   @Company                    :       Antrazal
// *   @Description                :       initialy call 
// *********************************************************************************************************
// */

// 	useEffect(() => {
// 		fetchPosts();
// 		setServiceImage()
// 	}, [name]);
//     const extractImageName = (url) => {
//         if (!url) return "Unknown Image";
//         const parts = url.split("/");
//         return parts[parts.length - 1].split(".")[0]; // Extract name without extension
//     };
// 	return (
// 		<div>
// 			{posts.map((item) =>
// 				item.name.toLowerCase().replace(/\s+/g, '-') === name ? (
// 					<div key={item.id}>
// 						<div className="headingbg">
// 							<div className="sevcontainer container">
// 								<div className="content-heading-div">
// 									<div className="heading_details">
// 										<div className='service-img-container'>
// 											<img src='/assets/salesforce-with-type-logo.svg' alt="" className='salesforcelogo' />
// 											<div className='service-icon'>
// 												<img key={item.serviceIcon} src={item.serviceIcon} alt={extractImageName(item.serviceIcon || '')} />
// 											</div>
// 										</div>
// 										<div>
// 											<h1 style={{marginTop:'1rem'}}>
// 												<span style={{color:"#0d9dda"}}>{item.name}</span>
// 											</h1>
// 										</div>
// 										<div >
// 											<li dangerouslySetInnerHTML={{ __html: item.content }}></li>
// 										</div>
// 										<Link  rel="canonical" to="/#contact">
// 											<button className="service-contact-btn" variant="primary">Meet an Expert
// 											</button>
// 										</Link>
// 									</div>

// 								</div>
// 							</div>
// 						</div>
// 						<CarouselLogo />
// 						<ScrollToTop />
// 						<AreasExpertise AreasExpertise={item.areaOfExpertises} id={item.id} />
// 						<RoiFocused />
// 						<CountUpSection />
// 						<SetMeeting />
// 					</div>
// 				) : null
// 			)}
// 		</div>
	
// 	);
// }



export default function Sales() {
	const [posts, setPosts] = useState([]);
	const [name, setName] = useState(''); // Remove "/services" from the beginning
console.log(name);
const location = useLocation();
useEffect(() => {
	// Extract the service name from the URL (after "/services/")
	const extractedName = location.pathname.replace(/^\/services\//, '');
	setName(extractedName);
}, [location]); // Re-run when the URL changes

	console.log('searchParams',name)
	const [SerLogo, setServiceLogo] = useState('')
	const [arrayOfItems, setArrayOfItems] = useState({
		id: '',
		items: [],
	});
	useEffect(() => {
        // Fetch the posts only when `name` is set and has changed
        if (name) {
            fetchPosts();
        }
    }, [name]); // Re-run when `name` changes

	TabTitle(`${name || 'Services'}  | Salesforce Development Services | Antrazal`,'Antrazal’s salesforce consulting company delivers exceptional Salesforce services worldwide. With global expertise and a team of experts.')
	/*
*********************************************************************************************************
*   @React Component Name       :       Sales
*   @Method                     :       fetchPosts
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       fetch the data from the server for the service page 
*********************************************************************************************************
*/
	const fetchPosts = () => {
		fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/services`)
			.then((res) => res.json())
			.then((resData) => {
				if (resData.length > 0) {
					
					// const firstCard = resData.find((item) => item.name === name);
					const firstCard = resData.find((item) => {
                        const formattedName = item.name.toLowerCase().replace(/\s+/g, '-');
                        return formattedName == name;
                    });
					console.log('firstCard', firstCard)

					if (firstCard) {
						const match = firstCard.content.match(/<li>.*?<\/li>/g);
						const items = match ? match.map((m) => m.slice(4, -5)) : [];
						setArrayOfItems({
							id: firstCard.name,
							items: items,
						});
					}
				}
				setPosts(resData);
			})
			.catch((err) => {
				console.error('Error fetching blog posts:', err);
			});
	};

	/*
*********************************************************************************************************
*   @React Component Name       :       Sales
*   @Method                     :       setServiceImage
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       set the service image as the path of the url 
*********************************************************************************************************
*/

	const setServiceImage = () => {
		ServicesDetailsMetaData.map((ServicesDetails) => {
			ServicesDetails.path === name ? setServiceLogo(ServicesDetails.img) : setServiceLogo(null)
		})
	}

	/*
*********************************************************************************************************
*   @React Component Name       :       Sales
*   @Method                     :       useEffect
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       initialy call 
*********************************************************************************************************
*/

	useEffect(() => {
		fetchPosts();
		setServiceImage()
	}, [name]);
    const extractImageName = (url) => {
        if (!url) return "Unknown Image";
        const parts = url.split("/");
        return parts[parts.length - 1].split(".")[0]; // Extract name without extension
    };
	return (
		<div>
			{posts.map((item) =>
				item.name.toLowerCase().replace(/\s+/g, '-') === name ? (
					<div key={item.id}>
						<div className="headingbg serviceDetailBackgroundImage">
							<div className="sevcontainer container">
								<div className="content-heading-div">
									<div className="heading_details">
										<div className='service-img-container'>
											<img src='/assets/salesforce-with-type-logo.svg' alt="" className='salesforcelogo' />
											<div className='service-icon'>
												<img key={item.serviceIcon} src={item.serviceIcon} alt={extractImageName(item.serviceIcon || '')} />
											</div>
										</div>
										<div>
											<h1 style={{marginTop:'1rem'}}>
												<span style={{color:"#0d9dda"}}>{item.name}</span>
											</h1>
										</div>
										<div >
											<li dangerouslySetInnerHTML={{ __html: item.content }}></li>
										</div>
										<Link  rel="canonical" to="/#contact">
											<button className="service-contact-btn" variant="primary">Meet an Expert
											</button>
										</Link>
									</div>
								</div>
								<div>
									{/* <img src="/assets/01Marketing Cloud service pillars.png" alt="" style={{height:"4rem"}}/> */}
								</div>
							</div>
						</div>








						<CarouselLogo />
						<ScrollToTop />
						<AreasExpertise AreasExpertise={item.areaOfExpertises} id={item.id} />
						<RoiFocused />
						<CountUpSection />
						<SetMeeting />
					</div>
				) : null
			)}
		</div>
	
	);
}