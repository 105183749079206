import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { HashLink as Link } from "react-router-hash-link";
import { TabTitle } from "../pages/TabTitle";

/*
*********************************************************************************************************
*  @Parent Component           :    Main page 
*  @React Component Name       :    Service
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Service 
********************************************************************************************************
*/

const Service = () => {

	const [posts, setPosts] = useState([]);

	/*
	*********************************************************************************************************
	*   @React Component Name       :       Service
	*   @Method                     :       fetchPosts
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       fetch the service data from the server 
	*********************************************************************************************************
	*/

	const fetchPosts = () => {
		fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/services`)
			.then((res) => res.json())
			.then((resData) => {
				setPosts(resData);
			})
			.catch((err) => {
				console.error('Error fetching blog posts:', err);
			});
	};

	useEffect(() => {
		fetchPosts();
	}, []);
	const extractImageName = (url) => {
		if (!url) return "Unknown Image";
		const parts = url.split("/");
		return parts[parts.length - 1].split(".")[0]; // Extract name without extension
	};
	return (

		<>
			<section className="services_bg" id="service">
				<Container style={{ paddingTop: "3rem" }}>
					<div className="">
						<h2 className="heading">Services</h2>
						<h2 className="title_sub" style={{ fontSize: '1rem' }}>
							Salesforce Product Implementation, Customised development and
							Consulting.
						</h2>
					</div>
					<div className="section-row" style={{ padding: "20px 0" }}>
						{posts && posts.map((val, index) => {
							const { id, name, serviceIcon, shotDescription } = val;
							const newName = name.replace(/&/g, "%26");
							return (
								<div key={index}>
									<div className="srv " data-toggle="modal" key={id}>
										<img src={val.serviceIcon} alt={extractImageName(val.serviceIcon || '')} style={{ height: "3rem", marginBottom: "0.5rem" }} />
										<h2>{name}</h2>
										<h3 dangerouslySetInnerHTML={{ __html: shotDescription }} className="service-dis-section">
										</h3>
										{/* <Link to={{
											pathname: "/services/",
											search: `?page=${newName}`,
										}}  rel="canonical">
											<button className="srv srvbtn" variant="primary">Know More
											</button>
										</Link> */}
										<Link 
											to={`/services/${name
												.toLowerCase()
												.replace(/\s+/g, '-')}`}
											rel="canonical"
										>
											<button className="srv srvbtn" variant="primary">
												Know More
											</button>
										</Link>

									</div>
								</div>
							);
						})}
					</div>
				</Container>
			</section>
		</>
	);
};

export default Service;
