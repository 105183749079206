import React from "react";
import { Row, Col } from "react-bootstrap";
import "../Components/css/About.css";
import {TabTitle} from '../pages/TabTitle'
const About = () => {
	TabTitle('About us | Salesforce Development Services | Antrazal','Antrazal’s salesforce consulting company delivers exceptional Salesforce services worldwide. With global expertise and a team of experts.')
	return (
		<>
			<section className="aboutScetionMain-div" id="home">
				<div style={{}} className="container">
					<Row className="aligh-items-center banner-row-div">
						<div className="about-banner-left-div">
							<h1 style={{ color: "black" }}><span style={{ color: "black", backgroundColor: "#fcd84a" }} className="aboutScetionMain-div-main-heading">AI-visioned </span><span style={{ color: "#041764" }}>customer</span><span style={{ color: "#3ca3e1" }}>360</span></h1>
							<h1 className="roiSectionHeading">Our ROI focused approach to <br />Salesforce Implementions. </h1>
							<div className="btn-home-container">
								<button className="aboutBtns" style={{ backgroundColor: "#063ddb" }} ><b ><a href="https://calendly.com/info-gmgd/30min" target="_blank" style={{ color: "white" }}>Book a free Consultation</a></b></button>
							</div>
						</div>
						{/*<div className="aboutUSbanner-right-div">
						 <div className="triangle-container">
								<div className="triangle">
									<p>200+ <br />
										Salesforce <br /> CRM Successes</p>
								</div>
								<div className="triangle">
									<p>150+ <br />
										Salesforce <br />Marketing Cloud <br />Stories</p>
								</div>
								<div className="triangle">
									<p>50+ <br />
										Salesforce <br /> Vlocity Execution</p>
								</div>
							</div>
							
						</div> */}
						<div className="srv-pillersmain-main-div">
							<div className="srv-pillersmain-div">
								<p className="pillar-text">We <br /> Create.</p>
							</div>
							<div className="srv-pillersmain-div">
								<p className="pillar-text">We <br /> Build.</p>
							</div>
							<div className="srv-pillersmain-div">
								<p className="pillar-text">We <br /> Solve.</p>
							</div>

						</div>

					</Row>
				</div>
			</section>
			<div className="container">
				<h3 className=" aboutWecreateSection" style={{}}>
					<span style={{ fontSize: '1.75rem', color: '#053ddb' }}>We create. </span>
					<span style={{ fontSize: '1.75rem', color: '#172ba3' }}>We build. </span>
					<span style={{ fontSize: '1.75rem', color: '#9b1ea4', fontWeight: 'bolder' }}>We solve.</span>
				</h3>
				<div className="aboutSectionValeHeading" style={{}}>
					<span style={{ fontSize: '2.4rem', fontWeight: 600 }} >Values We stand By </span>
				</div>
				<div className="aboutsection-div">
					<div className="sarea" style={{ background: '#eb72ec' }}>
						<h3><span style={{ fontWeight: 'bolder', fontSize: '2rem' }}>I</span>ntegrity</h3>
					</div>
					<div className="sarea" style={{ background: '#fcd84a' }}>
						<h3><span style={{ fontWeight: 'bolder', fontSize: '2rem' }}>T</span>ransparency</h3>
					</div>
					<div className="sarea" style={{ background: '#63cc68' }}>
						<h3><span style={{ fontWeight: 'bolder', fontSize: '2rem' }}>I</span>nnovation</h3>
					</div>
					<div className="sarea" style={{ background: '#6ff3af' }}>
						<h3><span style={{ fontWeight: 'bolder', fontSize: '2rem' }}>T</span>eamwork</h3>
					</div>
					<div className="sarea" style={{ background: '#60e2f3' }}>
						<h3><span style={{ fontWeight: 'bolder', fontSize: '2rem' }}>L</span>eadership</h3>
					</div>
				</div>
				<div className=" gallary-main-div">
					<span style={{ fontSize: '2.4rem', fontWeight: 600 }}>Our Team Images</span>
					<br />
					<div className="gallery">
						<div>
							<img src="https://backendtestinternal.s3.amazonaws.com/Copy%20of%20IMG_4189.jpg" />
							<img src="https://backendtestinternal.s3.amazonaws.com/Copy%20of%20IMG_4301.jpg" />
							<img src="https://backendtestinternal.s3.amazonaws.com/IMG_4342.jpg" alt="" />
							<img src="https://backendtestinternal.s3.amazonaws.com/Copy%20of%20IMG_4311.jpg" />
						</div>
						<div>
							<img src="https://backendtestinternal.s3.amazonaws.com/Image_20230906_152907_515%20(1).jpeg" alt="" />
							<img src="https://backendtestinternal.s3.amazonaws.com/Copy%20of%20IMG_4230.jpg" />
							<img src="https://backendtestinternal.s3.amazonaws.com/Copy%20of%20IMG_4348.jpg" />
						</div>
						<div>
							<img src="https://backendtestinternal.s3.amazonaws.com/IMG_4038.jpg" />
							<img src="https://backendtestinternal.s3.amazonaws.com/Copy%20of%20IMG_4356.jpg" />
							<img src="https://backendtestinternal.s3.amazonaws.com/IMG_20231104_210531.jpg" />
							<img src="https://backendtestinternal.s3.amazonaws.com/IMG_20231104_210550.jpg" alt="" />
							<img src="https://backendtestinternal.s3.amazonaws.com/Copy%20of%20IMG_4355.HEIC" alt="" />
						</div>
					</div>
				</div>

			</div>
		</>
	);
};

export default About;
