import React, { useState, useEffect, useCallback } from 'react';
import { HashLink as Link } from "react-router-hash-link";
import './css/hrmNavBar.css'
import './css/adminPageOfHrm.css';
import './css/mobileViewMainPage.css'
import TaskDetailView from './TaskDetailView';
export default function HrmNavbar() {
    const [profileToggle, setProfileToggle] = useState(false)
    const person = localStorage.getItem('contactUserName')
    const [searchTermByTaskId, setSearchTermByTaskId] = useState('')
    const [selectedId, setSelectedId] = useState(null);
    const [messageFromBackend, setMessageFromBackend] = useState('');
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('comments');
    const [projectData, setProjectData] = useState([]);
    const [allUserOption, setAllUserOption] = useState([])
    const [newFilteredData, setNewFilteredData] = useState([]);
    const [messageType, setMessageType] = useState('');
    const [taskIds, setTaskIds] = useState([]);
    const [suggestionsForTaskId, setSuggestionsForTaskId] = useState([]);
    const [toggleToKanban, setToggleToKanban] = useState(true)
    const [firstConditonOfTaskChild, setFirstConditonOfTaskChild] = useState(false)
    const [contact, setContact] = useState([]);
    const [loadingSectionRelatedItem, setLoadingSectionRelatedItem] = useState(false)
    const [loadingSectionComment, setLoadingSectionComment] = useState(false)
    const [loadingSectionHistory, setLoadingSectionHistory] = useState(false)
    const [relativeTask, setRelativeTask] = useState([]);
    const [projectComments, setprojectComments] = useState([]);
    const [newProjectHistory, setNewProjectHistory] = useState([]);

    const handleLogoutOfhrm = () => {
        localStorage.clear();
    }
    const fetchContact = useCallback(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getContact`)
            .then((res) => res.json())
            .then((resData) => {
                setContact(resData.records);
                const userOptions = resData.records.map(contact => ({
                    label: contact.Name,
                    value: contact.Id
                }));
                setAllUserOption(userOptions)
            })
            .catch((err) => {
                setMessageWithTimeout('Contacts not fetch', 'error');
                setLoading(false);
                console.error('Error fetching contact:', err);
            });
    }, []);
    const setMessageWithTimeout = (message, typeOFmessage) => {
        setMessageFromBackend(message);
        setMessageType(typeOFmessage);
        setTimeout(() => {
            setMessageFromBackend('');
            setMessageType('');
        }, 3000);
    };
    const fetchTaskIds = useCallback(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getAllTaskId/${localStorage.getItem('contactUserId')}`)
            .then((res) => res.json())
            .then((resData) => {
                
                const userOptions = resData.records.map(project => ({
                    label: project.Name,
                    value: project.Id
                }));
                setTaskIds(userOptions);
                setLoading(false);
            })
            .catch((err) => {
                setMessageWithTimeout('Project TaskId not fetch  ', 'error');
                console.error('Error fetching contact:', err);
            });
    }, []);
    useEffect(() => {

        fetchTaskIds()
        fetchContact()
    }, []);
    const profileToggleFun = () => {
        setProfileToggle(!profileToggle)
    }

    const handleInputChange = (e) => {
        const searchValue = e.target.value;
        setSearchTermByTaskId(searchValue);
        if (searchValue.trim()) {
            const filteredSuggestions = taskIds.filter(task =>
                task.label.toLowerCase().includes(searchValue.toLowerCase())
            );
            setSuggestionsForTaskId(filteredSuggestions);
        } else {
            setSuggestionsForTaskId([]);
        }
    };
    const SetActiveTabCommentOrHistory = (tab) => {
        setActiveTab(tab)
    }
    const handleIdClick = (id) => {
        setSelectedId(id)
        SetActiveTabCommentOrHistory('comments')
        setSelectedId(id === selectedId ? '' : id);
        if (id === '') {
            return null;
        }
        else {
            fetchProjectDetail(id)
        }
    };
    const fetchProjectRelativeTask = useCallback(async (id) => {
        if (!id) {
            return;
        }
        setLoadingSectionRelatedItem(true);
        setRelativeTask([])
        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getEntityrelativeTask/${id}`);
            const resData = await res.json();
            setRelativeTask(resData.records);
            if (resData.records[0].Parent_Entity__r) {

            }
        } catch (err) {
            setMessageWithTimeout('Failed to fetch project relative tasks', 'error');
            console.error('Error fetching relative tasks:', err);
        } finally {
            setLoadingSectionRelatedItem(false);
        }
    }, []);

    const fetchProjectComments = useCallback(async (id) => {
        if (!id) {
            return;
        }
        setLoadingSectionComment(true);
        setprojectComments([])
        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getEntitycomments/${id}`);
            const resData = await res.json();
            setprojectComments(resData.records);
        } catch (err) {
            setLoadingSectionComment(false);
            setMessageWithTimeout('Failed to fetch project comments', 'error');
            console.error('Error fetching comments:', err);
        } finally {

            setLoadingSectionComment(false);
        }
    }, []);

    const fetchProjectHistory = useCallback(async (id) => {
        if (!id) {
            return;
        }
        setLoadingSectionHistory(true);
        setNewProjectHistory([])
        try {
            const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getEntityHistoryByTaskId/${id}`);
            const resData = await res.json();
            setNewProjectHistory(resData.records);
        } catch (err) {
            setLoadingSectionHistory(false);
            setMessageWithTimeout('Failed to fetch Task History', 'error');
            console.error('Error fetching history:', err);
        } finally {
            setLoadingSectionHistory(false);

        }
    }, []);

    const fetchProjectDetail = useCallback(async (id) => {
        if (!id) {
            return;
        } else {
            setLoading(true);
            setProjectData([])
            setRelativeTask([])
            setNewProjectHistory([])
            setprojectComments([])
            try {
                const res = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/EntityDetails/${localStorage.getItem('contactUserId')}/${id}`);
                const resData = await res.json();
               
                if (!resData.records || resData.records.length <= 0) {
                    setSelectedId(null);
                    setToggleToKanban(false);
                    return;
                }
                setProjectData(resData.records);
                setLoading(false);
                const project = resData.records[0]
                if (
                    ['Development', 'Solution Design', 'Unit Testing', 'Review', 'Deployment'].some(prefix => project.Title__c.startsWith(prefix)) &&
                    project.RecordType.Name === 'Task' && project.Parent_Entity__r.RecordType.Name === "User Story"
                ) {
                    setFirstConditonOfTaskChild(true);
                    SetActiveTabCommentOrHistory('history')
                }
                else {
                    setFirstConditonOfTaskChild(false);
                }
                await fetchProjectRelativeTask(id);
                await fetchProjectComments(id);
                await fetchProjectHistory(id);
            } catch (err) {
                setLoading(false);
                setMessageWithTimeout('Failed to fetch project data', 'error');
                console.error('Error fetching project details:', err);
            } finally {
                setLoading(false);
            }
        }
    }, [fetchProjectRelativeTask, fetchProjectComments, fetchProjectHistory]);

    const handleSuggestionClick = (suggestion) => {
        handleIdClick(suggestion.value)
        setSearchTermByTaskId(suggestion.label);
        const searchValue = suggestion.label.trim();
        if (!searchValue) {
            alert("Please enter a search term.");
            return;
        }
        setLoading(true);
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getEntityDetailsTaskId/${searchValue}`)
            .then((res) => res.json())
            .then((resData) => {
                setNewFilteredData(resData.records)
                setLoading(false);
                setSearchTermByTaskId('')
            })
            .catch((err) => {
                setLoading(false);
                setMessageWithTimeout(`faild to  Search Task Id ${searchValue} Data `, 'error');
                setSearchTermByTaskId()
            });
        setSuggestionsForTaskId([]);
    };
    const updateProjectComments = (newComment) => {
        setprojectComments(prevProjectData => {
            const newRecord = {
                "Id": newComment.Id,
                "Comment__c": newComment.content,
                "CreatedDate": new Date().toISOString(),
                "Commented_By__r": {
                    "Name": localStorage.getItem('contactUserName')
                }
            };

            if (!prevProjectData || !prevProjectData.length || !prevProjectData[0].Project_Entity_Commnets__r) {
                const updatedProject = {
                    ...prevProjectData[0],
                    Project_Entity_Commnets__r: {
                        records: [newRecord]
                    }
                };
                return [updatedProject];
            }

            const existingRecords = [...prevProjectData[0].Project_Entity_Commnets__r.records];
            existingRecords.unshift(newRecord);

            const updatedProject = {
                ...prevProjectData[0],
                Project_Entity_Commnets__r: {
                    ...prevProjectData[0].Project_Entity_Commnets__r,
                    records: existingRecords
                }
            };

            return [updatedProject];
        });
    };
    const SetLoader = (val) => {
        setLoading(val)
    }
    const handelClosedBtnKanbanDetails = () => {
        setSelectedId('')
    }
    return (
        <div className='hrmAdminPanelNavbar' style={{}}>

            <Link  rel="canonical" className="headingOfhrm" style={{
                fontWeight: 800, color: "#1011a2;", fontSize: '1.5rem'
            }} to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm">A<span style={{ color: "#1011a2;", fontWeight: 800, letterSpacing: '1px' }}
            >WC</span></Link>
            <div className='hrmNavLeftDiv' style={{ gap: "0.5rem" }}>

                {/* <div className="centerClass">
                    <div className='searchDiv'>
                        <input
                            placeholder="search"
                            className='navBarSearch'
                        />
                    </div>
                </div> */}
                <div className=" search-div">
                    <div className='searchDiv'>
                        <input
                            placeholder="Type to search..."
                            value={searchTermByTaskId}
                            onChange={handleInputChange}
                            style={{ width: '100%' }}
                        />
                    </div>
                    {suggestionsForTaskId.length > 0 && (
                        <ul className="suggestions-list" style={{ listStyle: "none" }}>
                            {suggestionsForTaskId.map((suggestion) => (
                                <li key={suggestion.value} onClick={() => handleSuggestionClick(suggestion)} style={{ margin: 0 }}>
                                    {suggestion.label}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className='profile-div centerClass'>
                    <div className='profileButton' onClick={() => profileToggleFun()}>{localStorage.getItem('contactUserName')[0] || "N/A"}</div>
                    <div className='dropDownForProfileDiv' style={{ display: `${profileToggle ? 'block' : 'none'}` }}>
                        <div className='drop-profile-div'>
                            <p>{localStorage.getItem('contactUserName')}</p>
                        </div>
                        <Link  rel="canonical" to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm" className='drop-profile-div' onClick={() => profileToggleFun()}>
                            <div className='drop-profile-div'>
                                Home
                            </div>
                        </Link>
                        <Link  rel="canonical" to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm/timeCard" className='drop-profile-div' onClick={() => profileToggleFun()}>
                            <div className='drop-profile-div' >
                                Log Time
                            </div>
                        </Link >
                        <Link  rel="canonical" to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/adminPanelHrm/leaveform" className='drop-profile-div' onClick={() => profileToggleFun()}>
                            <div className='drop-profile-div' >
                                Leaves
                            </div>
                        </Link>
                        <Link  rel="canonical" to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/hrmLoginPage" className='drop-profile-div' >
                            <div className='drop-profile-div' style={{ color: 'red' }}
                                onClick={handleLogoutOfhrm}>Logout
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            {
                toggleToKanban && selectedId ? (
                    <TaskDetailView
                        selectedId={selectedId}
                        projectData={projectData}
                        allUserOption={allUserOption}
                        handelClosedBtnKanbanDetails={handelClosedBtnKanbanDetails}
                        updateProjectData={updateProjectComments}
                        loading={loading} SetLoader={SetLoader}
                      setMessageWithTimeout={setMessageWithTimeout}
                        handleIdClick={handleIdClick}
                        relativeTask={relativeTask}
                        newProjectHistory={newProjectHistory}
                        projectComments={projectComments}
                        loadingSectionRelatedItem={loadingSectionRelatedItem}
                        loadingSectionComment={loadingSectionComment}
                        loadingSectionHistory={loadingSectionHistory}
                        SetActiveTabCommentOrHistory={SetActiveTabCommentOrHistory}
                        activeTab={activeTab}
                        firstConditonOfTaskChild={firstConditonOfTaskChild}
                    />
                ) : null
            }
        </div>
    )
}
