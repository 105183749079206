import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";

function CarouselLogo() {
  const [posts, setPosts] = useState([]);

  const fetchPosts = () => {
    fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/testimonial`)
      .then((res) => res.json())
      .then((resData) => {
        setPosts(resData);
      })
      .catch((err) => {
        console.error('Error fetching blog posts:', err);
      });
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const duplicatedPosts = [...posts, ...posts]; 
  const extractImageName = (url ) => {
    if (!url) return "Unknown Image";
    const parts = url.split("/");
    return parts[parts.length - 1].split(".")[0]; // Extract name without extension
};
  return (
    <div className="container">

  
    <AppContainer>
      <Wrapper>
        <Marquee>
          <MarqueeGroup>
            {duplicatedPosts.map((currentElement, index) => {
              const { id, organisation_logo } = currentElement;
              return (
                <ImageGroup key={id ? id : `logo-${index}`}>
                  <Image src={organisation_logo} alt={extractImageName(organisation_logo || '' )} />
                </ImageGroup>
              );
            })}
          </MarqueeGroup>
        </Marquee>
      </Wrapper>
    </AppContainer>
    </div>
  );
}

export default CarouselLogo;

const AppContainer = styled.div`
  color: #000000;
  height: 25vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 780px) {
    height: 15vh;
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 580px) {
    height: 100%;
  }
`;

const Marquee = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  user-select: none;
    will-change: transform;
`;

const scrollX = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%); /* Adjust to scroll halfway, then loop */
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  animation: ${scrollX} 10s linear infinite;
  will-change: transform;
`;

const MarqueeGroup = styled.div`
  ${common}
`;

const ImageGroup = styled.div`
  display: grid;
  place-items: center;
`;

const Image = styled.img`
  height: 50px;
  margin: 0 30px;
  max-width: 225px;

  @media (max-width: 580px) {
    height: 40px;
    margin: 0 20px;
  }
`;
